import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { award, cross } from "../assets";
import { verifyEmail, verifyOtp } from "../api/auth";
import { useAuth } from "../context/AuthContext";

const EmailVerification = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("ready");
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (status === "loading") return;
    setStatus("loading");
    const callTokenCheck = async () => {
      const tokenType = parseInt(searchParams.get("tokenType"));
      const token = searchParams.get("token");
      // 0-Email, 1-OTP
      try {
        if ((tokenType !== 0 && tokenType !== 1) || token === null) {
          throw new Error("Invalid token");
        }
        if (tokenType === 0) {
          await verifyEmail(token);
        } else if (tokenType === 1) {
          await verifyOtp(token);
          auth.refreshUser();
        }
        setStatus("success");
      } catch (error) {
        if (!error.cancelled) {
          console.error("Email verification error:", error);
          setStatus("error");
        }
      }
    };
    callTokenCheck();
  }, []);

  useEffect(() => {
    if (status === "loading") return;

    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown <= 0) {
      clearInterval(timer);
      if (status === "success") {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    }

    return () => clearInterval(timer);
  }, [countdown, status, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F4F4F4",
        padding: 3,
        flexGrow: 1,
      }}
    >
      {status === "loading" && (
        <Typography variant="h6" sx={{ color: "#333333", textAlign: "center" }}>
          Verifying...
        </Typography>
      )}

      {status === "success" && (
        <>
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={award}
              alt="Verified"
              style={{ width: 100, height: 100 }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#333333", textAlign: "center" }}
          >
            Congratulations! Your ONeID is verified.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#00CC00",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 3,
            }}
          >
            Redirecting to Dashboard in {countdown} seconds...
          </Typography>
        </>
      )}

      {status === "error" && (
        <>
          <Box sx={{ marginBottom: 2 }}>
            <img src={cross} alt="Error" style={{ width: 100, height: 100 }} />
          </Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#333333", textAlign: "center" }}
          >
            Verification Failed!
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555555", textAlign: "center", marginTop: 1 }}
          >
            The verification link is invalid or expired.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#FF0000",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 3,
            }}
          >
            Redirecting to Home in {countdown} seconds...
          </Typography>
        </>
      )}
    </Box>
  );
};

export default EmailVerification;
