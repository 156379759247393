import React, { useState } from "react";
import { Button, TextField, Typography, Box, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EmailSentPrompt from "./components/EmailSentPrompt";
import { register } from "../api/auth";
import { landingPageBackground } from "../assets";

const Register = () => {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    username: false,
    email: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
  });

  // State to control whether to show EmailSentPrompt or the form
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    // Basic email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? "" : "Enter a valid email address";
  };

  const validatePhone = (phoneNumber) => {
    // Basic phoneNumber number validation (10 digits)
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber) ? "" : "Enter a 10-digit phone number";
  };

  const validatePassword = (password) => {
    const hasLength = password.length >= 8;
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasAtLeastOneSpecialChar = /[!@#$%]/.test(password);
    const hasOnlyAllowedChars = /^[a-zA-Z0-9!@#$%]+$/.test(password);

    if (!hasLength) {
      return "Password must be at least 8 characters long";
    } else if (!hasNumbers) {
      return "Password must include a number";
    } else if (!hasLetters) {
      return "Password must include a letter";
    } else if (!hasAtLeastOneSpecialChar) {
      return "Password must include special characters (!@#$%)";
    } else if (!hasOnlyAllowedChars) {
      return "Password can only include letters, numbers, and special characters (!@#$%)";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    const { username, email, phoneNumber, password, confirmPassword } =
      formData;

    // Check if fields meet validation requirements
    const newErrors = {
      username: username === "" ? "Username is required" : false,
      email: validateEmail(email),
      phoneNumber: validatePhone(phoneNumber),
      password: validatePassword(password),
      confirmPassword:
        password !== confirmPassword ? "Passwords do not match" : false,
    };

    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error);

    // set Email to lowercase

    if (!hasErrors) {
      setLoading(true);
      formData.email = formData.email.toLowerCase();
      try {
        await register(formData);
        setIsSubmitted(true);
      } catch (error) {
        console.error("Register error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      className="flex items-center justify-center flex-grow"
      sx={{
        backgroundImage: isSubmitted ? "none" : `url(${landingPageBackground})`,
      }}
    >
      {isSubmitted ? (
        // Show the EmailSentPrompt if the form is submitted
        <EmailSentPrompt />
      ) : (
        <Box
          className="flex flex-col items-center space-y-4 p-8"
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: theme.palette.text.primary, fontWeight: "bold" }}
          >
            Create Your ONeID
          </Typography>

          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />

          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />

          {/* phoneNumber */}
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
          />

          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />

          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            variant="outlined"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
              color: theme.palette.secondary.contrastText,
              fontWeight: "bold",
            }}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress
                  sx={{ color: theme.palette.secondary.contrastText }}
                  size={20}
                />
              ) : null
            }
          >
            Create Your ONeID
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Register;
