import React from "react";
import { AutorenewRounded } from "@mui/icons-material";

const FullScreenLoader = ({ open, loadingText = "Loading..." }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black/50 z-50">
      <AutorenewRounded className="w-12 h-12 text-white animate-spin" />
      <h2 className="mt-4 text-xl text-white">{loadingText}</h2>
    </div>
  );
};

export default FullScreenLoader;
