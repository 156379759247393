import React from "react";
import { Box, Typography } from "@mui/material";
import { stopwatch } from "../../assets";

const EmailSentPrompt = () => {
  return (
    <Box
      className="flex-grow flex items-center justify-center flex-col"
    >
      {/* Placeholder icon */}
      <Box sx={{ marginBottom: 2 }}>
        {/* Replace with your custom icon */}
        <img src={stopwatch} alt="stopwatch" style={{ width: 100, height: 100 }} />
      </Box>
      
      {/* Primary message */}
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333333", textAlign: "center" }}>
        We sent you the one-time link to your email.
      </Typography>

      {/* Secondary message */}
      <Typography variant="body1" sx={{ color: "#555555", textAlign: "center", marginTop: 1 }}>
        Please check your email to get access to ONeID.
      </Typography>
    </Box>
  );
};

export default EmailSentPrompt;