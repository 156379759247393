import React, { useState } from "react";
import { Box, TextField, Button, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EmailSentPrompt from "./components/EmailSentPrompt";
import { logo, landingPageBackground } from "../assets";
import { login } from "../api/auth";
import { useNotification } from "../context/NotificationContext";

const Login = () => {
  const theme = useTheme();
  const { showNotification } = useNotification();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      await login(formData);
      setSubmitted(true);
      showNotification("Login successful", "success");
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
      showNotification("Login failed. Please try again", "error");
      return;
    }
  };

  if (submitted) {
    return <EmailSentPrompt />;
  }
  return (
    <Box
      className="flex flex-col items-center justify-center flex-grow bg-blue-500 p-8"
      sx={{
        backgroundImage: `url(${landingPageBackground})`,
      }}
    >
      <Box className="flex flex-col items-center justify-center bg-white bg-opacity-50 p-8 gap-4 rounded-3xl md:w-full">
        <img src={logo} alt="ONeID" className="mx-auto h-48" />
        <Typography
          variant="h6"
          className="border-b-4 border-white text-white !font-semibold"
        >
          Log In
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 w-full max-w-xs"
        >
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="bg-white rounded-md"
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="bg-white rounded-md"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.dark,
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress
                  sx={{ color: theme.palette.primary.contrastText }}
                  size={20}
                />
              ) : null
            }
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
