import React, { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Layout = ({ children }) => {
  const navbarRef = useRef(null);
  const footerRef = useRef(null);
  const [mainMaxHeight, setMainMaxHeight] = useState("auto");

  useEffect(() => {
    const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
    const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
    const windowHeight = window.innerHeight;
    const maxAvailableHeight = windowHeight - navbarHeight - footerHeight;

    setMainMaxHeight(`${maxAvailableHeight}px`);
  }, []);

  return (
    <Box className="flex flex-col min-h-screen bg-gray-50 text-gray-800">
      {/* Navbar */}
      <header ref={navbarRef} className="shadow-md sticky top-0 z-10 bg-white">
        <Navbar />
      </header>

      {/* Main Content (Needs to be optimized) */}
      <Box
        component="main"
        className="flex flex-grow overflow-y-auto w-full p-0"
        sx={{ maxHeight: mainMaxHeight }}
      >
        {children}
      </Box>

      {/* Footer */}
      <footer
        ref={footerRef}
        className="bg-gray-800 text-white py-4 mt-auto p-8"
      >
        <Footer />
      </footer>
    </Box>
  );
};

export default Layout;
