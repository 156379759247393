import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "./components";
import { AuthProvider } from "./context/AuthContext";
import { LoadingProvider } from "./context/LoadingContext";
import { NotificationProvider } from "./context/NotificationContext";
import AppRoutes from "./routes/AppRoutes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import appInsights from "./appInsights";

const theme = createTheme({
  typography: {
    fontFamily: "Segoe UI, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#0078D4",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#00FF00",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#F4F4F4",
      contrastText: "#000000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <NotificationProvider>
          <AuthProvider>
            <Router>
              <Layout>
                <AppRoutes />
              </Layout>
            </Router>
          </AuthProvider>
        </NotificationProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
