import React, { useEffect, useState } from "react";
import { Snackbar, IconButton } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Warning from "@mui/icons-material/Warning";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";

const getNotificationContent = (status) => {
  switch (status) {
    case "success":
      return {
        message: "Email verified successfully!",
        icon: <CheckCircle sx={{ color: "green" }} />,
      };
    case "error":
      return {
        message: "Email verification failed!",
        icon: <Error sx={{ color: "red" }} />,
      };
    case "failure":
      return {
        message: "Email verification rejected!",
        icon: <Warning sx={{ color: "orange" }} />,
      };
    case "pending":
    default:
      return {
        message: "Verifying email...",
        icon: <HourglassEmpty sx={{ color: "gray" }} />,
      };
  }
};

const NotificationSnackbar = ({ status, open, onClose }) => {
  const [snackPack, setSnackPack] = useState([]);

  useEffect(() => {
    if (status) {
      const { message, icon } = getNotificationContent(status);
      setSnackPack((prev) => [
        ...prev,
        { message, icon, key: new Date().getTime() },
      ]);
    }
  }, [status]);

  const handleClose = (event, reason) => {
    onClose();
  };

  const handleExited = () => {
    setSnackPack((prev) => prev.slice(1));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      key={snackPack[0] ? snackPack[0].key : undefined}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={snackPack[0] ? snackPack[0].message : undefined}
      action={<>{snackPack[0] ? snackPack[0].icon : null}</>}
    />
  );
};

export default NotificationSnackbar;
