import api from "../utils/api";

export const register = (formData) => {
    return api.handleRequest(
        () => api.post("/api/auth/register", formData),
        (response) => response
    );
};

export const login = (formData) => {
    return api.handleRequest(
        () => api.post("/api/auth/login", formData),
        (response) => response
    );
};

export const logout = () => {
    return api.handleRequest(
        () => api.post("/api/auth/logout"),
        (response) => response
    );
};

export const verifyEmail = (code) => {
    return api.handleRequest(
        () => api.get(`/api/auth/verify?code=${code}`),
        (response) => response
    );
};

export const verifyOtp = (code) => {
    return api.handleRequest(
        () => api.get(`/api/auth/otp?code=${code}`),
        (response) => response
    );
};