import React, { createContext, useContext, useState } from "react";
import { FullScreenLoader } from "../components";

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {isLoading && <FullScreenLoader open={isLoading} />}
    </LoadingContext.Provider>
  );
};
