import React from "react";
import { Button, Typography, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { landingPageBackground } from "../assets";

const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      className="flex flex-col items-start justify-center bg-blue-600 text-left relative flex-grow"
      sx={{
        backgroundImage: `url(${landingPageBackground}), linear-gradient(to bottom, #0078D4, #1F2937)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      {/* Content section */}
      <Box className="z-10 absolute p-8">
        <Typography variant="h6" className="text-white mb-4">
          UnifiedAI Technological Solutions
        </Typography>
        <Typography variant="h1" className="text-white !font-bold">
          ONeID
        </Typography>
        <Typography variant="h2" className="text-white !font-bold">
          Infinite Trust
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/register")}
          sx={{
            backgroundColor: theme.palette.secondary.dark,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            marginTop: "2em",
          }}
        >
          <Typography className="text-black !font-semibold">Create your ONeID</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default LandingPage;
