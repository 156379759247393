import axios from "axios";

const PUBLIC_URLS = [
  "/api/auth/login",
  "/api/auth/register",
  "/api/auth/refresh",
  "/api/auth/verify",
  "/api/auth/otp",
];
const REFRESH_TOKEN_URL = "/api/auth/refresh";

// Refreshes the access token using a stored refresh token
const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${REFRESH_TOKEN_URL}`,
      { refreshToken }
    );
    if (response.status === 200) {
      const { accessToken, refreshToken: newRefreshToken } = response.data.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      return accessToken;
    } else {
      throw new Error("Token refresh failed");
    }
  } catch (error) {
    console.error("Refresh token error:", error);
    throw error;
  }
};

const pendingRequests = new Map();

const addRequestToPending = (config) => {
  const requestKey = `${config.url}&${config.method}`;
  if (!pendingRequests.has(requestKey)) {
    pendingRequests.set(requestKey, config);
  }
};

const removeRequestFromPending = (config) => {
  const requestKey = `${config.url}&${config.method}`;
  if (pendingRequests.has(requestKey)) {
    pendingRequests.delete(requestKey);
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:5048",
  headers: {
    "Content-Type": "application/json",
  },
});

// Adds a request interceptor to handle authorization and prevent duplicate requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token && !PUBLIC_URLS.includes(config.url)) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const requestKey = `${config.url}&${config.method}`;
    if (pendingRequests.has(requestKey)) {
      // Rejects the duplicate request in progress with a custom error
      return Promise.reject({cancelled: true, message: "Duplicate request in progress"});
    } else {
      addRequestToPending(config);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Adds a response interceptor to handle the removal of pending requests and automatic token refresh
api.interceptors.response.use(
  (response) => {
    removeRequestFromPending(response.config);
    let { data } = response;
    if (!data) {
      return data;
    }
    if (data.code && data.code !== 200) {
      const message = data.message || "Unknown error";
      return Promise.reject(new Error(message));
    }
    if (data.data) {
      data = data.data;
      if (data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
      }
      if (data.refreshToken) {
        localStorage.setItem("refreshToken", data.refreshToken);
      }
    }
    return data;
  },
  async (error) => {
    console.error("API error:", error);
    console.error("Is 401 error?", error.response && error.response.status === 401);
    if (error.config) {
      removeRequestFromPending(error.config);
    }
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          api.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// General request handler to encapsulate try/catch logic
api.handleRequest = async (requestFn) => {
  try {
    return await requestFn();
  } catch (error) {
    if (error.cancelled) {
      return;  // Ignores cancellation errors silently
    }
    console.error("API error:", error);
    throw error;
  }
};

export default api;