import api from "../utils/api";

export const loadUser = () => {
  return api.handleRequest(() => api.get("/api/auth/my"));
};

export const getWebsocketConnId = () => {
  return api.handleRequest(() => api.get("/api/get-websocket-id"));
};

export const verifyUser = (formData) => {
  return api.handleRequest(() => api.post("/api/verification/request", formData));
}

export const updateVerificationStatus = (formData) => {
  return api.handleRequest(() => api.post("/api/verification/update", formData));
}