import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";

const ConfirmationModal = ({
  open,
  onDecision,
  title = "Please make your choice",
  message = "You have received a notification that requires immediate action. Do you agree?",
  closable = false,
  showDecision = true,
  yesLabel = "Yes",
  noLabel = "No",
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        if (closable) {
          onDecision(noLabel);
        }
      }}
      disableEscapeKeyDown
    >
      <Box className="bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-md mx-auto mt-24">
        <Typography variant="h6" className="mb-4">
          {title}
        </Typography>
        <Typography className="mb-6">{message}</Typography>
        <div className="flex justify-end space-x-4">
          {showDecision && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onDecision(yesLabel)}
              >
                {yesLabel}
              </Button>
              <Button variant="outlined" onClick={() => onDecision(noLabel)}>
                {noLabel}
              </Button>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
