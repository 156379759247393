import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const APP_INSIGHTS_KEY = '9934a639-3bd7-4203-b958-b7c9d9e421c2';

class AppInsightsService {
  static instance = null;

  static getInstance() {
    if (!this.instance) {
      try {
        this.instance = new ApplicationInsights({
          config: {
            instrumentationKey: APP_INSIGHTS_KEY,
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxErrorStatusText: true,
            enableAjaxPerfTracking: true,
            maxBatchSizeInBytes: 5120000,  
            maxBatchInterval: 15000,
            disableFetchTracking: false,
          },
        });

        this.instance.loadAppInsights();
        this.instance.trackPageView();
        
        window.onerror = (message, url, lineNo, columnNo, error) => {
          this.instance?.trackException({
            error: error || new Error(message),
            severityLevel: 3
          });
        };

      } catch (error) {
        console.error('Failed to initialize Application Insights:', error);
        throw error;
      }
    }
    return this.instance;
  }
}

export const appInsights = AppInsightsService.getInstance();
export default appInsights;