import React from "react";
import { Link } from "react-router-dom";
import { signUpPageBackground } from "../assets";
import { Box } from "@mui/material";

const NotFound = () => (
  <Box
    className="flex flex-col  justify-center items-center bg-blue-600 text-left relative flex-grow p-8"
    sx={{
      backgroundImage: `url(${signUpPageBackground}), linear-gradient(to bottom, #0078D4, #1F2937)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    <Box className="flex flex-col  justify-center items-center bg-white bg-opacity-50 p-8 gap-4 rounded-3xl md:w-full">
      <h1 className="text-4xl font-bold text-black">404 - Page Not Found</h1>
      <Link to="/" className="text-blue-800 underline">
        Go back to Home
      </Link>
    </Box>
  </Box>
);

export default NotFound;
