import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InputWithButton = ({
  value = "",
  setValue,
  buttonText = "Button",
  IconComponent = CheckCircleIcon,
  placeholder = "Enter value",
  onSubmit,
  onChange,
  type = "text",
  buttonVariant = "contained",
  buttonSx = {},
  error = "",
  helperText = "",
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-full max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="w-full">
        <TextField
          fullWidth
          type={type}
          value={value}
          onChange={handleChange}
          variant="outlined"
          label={placeholder}
          error={!!error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <Button
                onClick={handleSubmit}
                variant={buttonVariant}
                sx={buttonSx}
                endIcon={<IconComponent />}
                className="p-2"
              >
                {buttonText}
              </Button>
            ),
          }}
        />
      </form>
    </div>
  );
};

export default InputWithButton;
