import React from "react";
import { Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        {/* Brand Name or Logo */}
        <Typography variant="h6" className="text-white">
          ONeID
        </Typography>

        {/* Footer Middle Text */}
        <div className="text-center text-gray-400 mt-4">
          <Typography variant="body2">
            © {new Date().getFullYear()} ONeID. All rights reserved.
          </Typography>
        </div>

        {/* Footer Links */}
        <div className="hidden md:flex space-x-4">
          <Link
            href="/privacy"
            sx={{ color: "gray", "&:hover": { color: "white" } }}
          >
            Privacy Policy
          </Link>
          <Link
            href="/terms"
            sx={{ color: "gray", "&:hover": { color: "white" } }}
          >
            Terms of Service
          </Link>
          <Link
            href="/contact"
            sx={{ color: "gray", "&:hover": { color: "white" } }}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
