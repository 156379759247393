import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  EmailOutlined,
  VerifiedUserOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import {
  FullScreenLoader,
  InputWithButton,
  ConfirmationModal,
} from "../components";
import { useAuth } from "../context/AuthContext";
import NotificationSnackbar from "./components/NotificationSnackbar";
import {
  getWebsocketConnId,
  verifyUser,
  updateVerificationStatus,
} from "../api/user";

const Dashboard = () => {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(
    "pending" // "accepted" | "error" | "pending" | "rejected"
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    verificationId: undefined,
    title: "Verification Invitation",
    message: undefined,
  });
  const [verifiedUser, setVerifiedUser] = useState(null);

  const { user } = useAuth();
  const theme = useTheme();
  const ws = useRef(null);

  const connectWebSocket = async () => {
    try {
      const { connectionId } = await getWebsocketConnId();
      if (!connectionId) {
        throw new Error("Failed to get websocket connection ID");
      }
      const protocol = window.location.protocol === "https:" ? "wss" : "ws";
      const baseURL = process.env.REACT_APP_API_BASE_URL || "localhost:5048";
      const wsURL = `${protocol}://${baseURL.replace(
        /^https?:\/\//,
        ""
      )}/api/ws?connectionId=${connectionId}`;
      ws.current = new WebSocket(wsURL);
      ws.current.onopen = () => {
        setOpenNotification(false);
        console.log("Connected to websocket server");
      };
      ws.current.onmessage = (event) => {
        if (event.data instanceof Blob) {
          console.log("Heartbeat received");
          return;
        }
        const data = JSON.parse(event.data);
        console.log("Websocket message received:", data.type);
        switch (data.type) {
          case "verification":
            setOpenConfirmation(true);
            setConfirmationData({
              verificationId: data.verificationId,
              title: "Verification Invitation",
              message: `User ${data.requesterUsername} (${data.requesterEmail}) is requesting your verification.`,
            });
            break;
          case "verificationAccepted":
            setOpenNotification(false);
            setNotificationStatus("success");
            setOpenNotification(true);
            setVerifiedUser({
              username: data.confirmerUsername,
              email: data.confirmerEmail,
              phoneNumber: data.confirmerPhoneNumber,
              blockchainID: data.confirmerBlockchainId,
            });
            break;
          case "verificationRejected":
            setOpenNotification(false);
            setNotificationStatus("failure");
            setOpenNotification(true);
            break;
          default:
            console.log("Unknown message type:", data);
          //   setOpenNotification(true);
          //   setNotificationStatus("error");
        }
      };
      ws.current.onclose = () => {
        console.log("Disconnected from websocket server");
      };
      ws.current.onerror = (error) => {
        console.error("Websocket error:", error);
        setOpenNotification(false);
        setNotificationStatus("error");
        setOpenNotification(true);
      };
    } catch (error) {
      console.error("Failed to build websocket connection:", error);
    }
  };

  // build websocket connection
  useEffect(() => {
    connectWebSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!ws.current || ws.current.readyState === WebSocket.CLOSED) {
          connectWebSocket();
        }
      } else {
        if (ws.current) {
          ws.current.close();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const handleEmailVerify = async (email) => {
    if (!email) {
      setErrorEmail("Email is required");
      return;
    }
    if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
      setErrorEmail("Websocket connection is not ready");
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrorEmail("Invalid email format");
      return;
    }
    setLoading(true);
    setErrorEmail("");
    setOpenNotification(true);
    setNotificationStatus("pending");
    try {
      const response = await verifyUser({
        userRequesterId: user.userId,
        userRequestedEmail: email,
      });
      //   console.log(response);
    } catch (error) {
      setOpenNotification(true);
      setNotificationStatus("error");
      setErrorEmail(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmationDecided = async (confirmed) => {
    setOpenConfirmation(false);
    try {
      await updateVerificationStatus({
        verificationId: confirmationData.verificationId,
        status: confirmed ? 2 : 3,
      });
    } catch (error) {
      console.error("Failed to update verification status:", error);
    }
  };

  return (
    <Box
      className="flex justify-center flex-grow p-8 gap-8 max-w-full"
      sx={{
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {/* Left column - Form */}
      <Box className="flex flex-col items-start gap-2 flex-grow">
        <Box className="flex items-center gap-1 mb-8">
          <VerifiedUserOutlined
            sx={{
              color: theme.palette.primary.main,
              fontSize: { xs: 30, md: 40 },
            }}
          />
          <Typography variant="h6" className="!font-bold">
            User Information
          </Typography>
        </Box>
        <Box className="flex flex-col gap-1 break-all">
          <Typography className="flex flex-grow">
            <span className="font-bold mr-4">Username:</span>
            <span>{user?.username || "-"}</span>
          </Typography>
          <Typography className="flex flex-grow">
            <span className="font-bold mr-4">Email:</span>
            <span>{user?.email || "-"}</span>
          </Typography>
          <Typography className="flex flex-grow">
            <span className="font-bold mr-4">Phone:</span>
            <span>{user?.phoneNumber || "-"}</span>
          </Typography>
          <Typography className="flex flex-grow">
            <span className="font-bold mr-4">Blockchain ID:</span>
            <span>{user?.blockchainID || "-"}</span>
          </Typography>
        </Box>
      </Box>

      {/* divider */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          borderLeft: "1px solid #ccc",
          height: "100%",
        }}
      />

      {/* Right column - User information display */}
      <Box className="flex flex-col items-start gap-2">
        <Box className="flex items-center gap-1 mb-8">
          <EmailOutlined
            sx={{
              color: theme.palette.primary.main,
              fontSize: { xs: 30, md: 40 },
            }}
          />
          <Typography variant="h6" className="!font-bold">
            Verify Identity
          </Typography>
        </Box>
        <Box className="flex flex-col items-center justify-center gap-2 w-full">
          <InputWithButton
            value={email}
            setValue={setEmail}
            buttonText="Verify"
            placeholder="Enter email address"
            onSubmit={handleEmailVerify}
            type="email"
            buttonVariant="contained"
            error={!!errorEmail}
            helperText={errorEmail}
            buttonSx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          />
        </Box>
      </Box>

      {/* if verifiedUser  */}
      {verifiedUser && (
        <>
          {/* divider */}
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              borderLeft: "1px solid #ccc",
              height: "100%",
            }}
          />

          {/* Additional column - Form */}
          <Box className="flex flex-col items-start gap-2 flex-grow">
            <Box className="flex items-center gap-1 mb-8">
              <PermIdentityOutlined
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: { xs: 30, md: 40 },
                }}
              />
              <Typography variant="h6" className="!font-bold">
                Last Verified Partner
              </Typography>
            </Box>
            <Box className="flex flex-col gap-1 break-all">
              <Typography className="flex flex-grow">
                <span className="font-bold mr-4">Username:</span>
                <span>{verifiedUser.username || "-"}</span>
              </Typography>
              <Typography className="flex flex-grow">
                <span className="font-bold mr-4">Email:</span>
                <span>{verifiedUser.email || "-"}</span>
              </Typography>
              <Typography className="flex flex-grow">
                <span className="font-bold mr-4">Phone:</span>
                <span>{verifiedUser.phoneNumber || "-"}</span>
              </Typography>
              <Typography className="flex flex-grow">
                <span className="font-bold mr-4">Blockchain ID:</span>
                <span>{verifiedUser.blockchainID || "-"}</span>
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <FullScreenLoader
        open={loading}
        loadingText="Verifying via Blockchain..."
      />
      <NotificationSnackbar
        status={notificationStatus}
        open={openNotification}
        onClose={() => {
          setOpenNotification(false);
          setNotificationStatus("pending");
        }}
      />
      <ConfirmationModal
        open={openConfirmation}
        onDecision={onConfirmationDecided}
        title={confirmationData.title}
        message={confirmationData.message}
      />
    </Box>
  );
};

export default Dashboard;
