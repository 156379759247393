import React from "react";
import { AppBar, Toolbar, Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { logoWithText } from "../../assets";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Toolbar className="flex justify-between items-center px-4 bg-white">
        {/* Logo and Title */}
        <div className="flex items-center space-x-2">
          {/* <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ display: user ? "flex" : "none" }}
          >
            <MenuIcon />
          </IconButton> */}
          <Link to="/">
            <div className="flex items-center space-x-2">
              <img src={logoWithText} alt="oneID" className="h-8" />
              <div className="hidden md:flex text-black font-bold md:text-xl lg:text-2xl border-l-4 border-black pl-4">
                ONeID
              </div>
            </div>
          </Link>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-2">
          {/* hidden when in small screen */}
          <div className="hidden md:flex items-center justify-center italic text-gray-700 pr-2">
            {user ? null : <p>Create your ONeID</p>}
          </div>
          <div className="flex items-center">
            {!user && (
              <>
                <Button
                  component={Link}
                  to="/login"
                  sx={{
                    color: "black",
                  }}
                >
                  Log In
                </Button>
                {/* divider */}
                <div className="w-0.5 h-3 bg-gray-500 m-1 p-0" />
                <Button
                  component={Link}
                  to="/register"
                  sx={{
                    color: "black",
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
            {user && (
              <>
                <Button
                  component={Link}
                  to="/dashboard"
                  sx={{
                    color: "black",
                  }}
                >
                  Dashboard
                </Button>
                {/* divider */}
                <div className="w-0.5 h-3 bg-gray-400 m-1 p-0" />
                <Button
                  sx={{
                    color: "black",
                  }}
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                >
                  Log Out
                </Button>
              </>
            )}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
