import { Routes, Route } from "react-router-dom";
import {
  LandingPage,
  Register,
  EmailVerification,
  Login,
  Dashboard,
  NotFound,
} from "../pages";
import { ProtectedRoute } from "../components";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/register" element={<Register />} />
    <Route path="/verify-email" element={<EmailVerification />} />
    <Route path="/login" element={<Login />} />
    <Route element={<ProtectedRoute />}>
      <Route path="/dashboard" element={<Dashboard />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
